.category-finding-border {
    background-color: #ECEEEF;
    border-radius: 5px;
    height: 1rem;
    width: fit-content;
    padding: 2px 5px;
}

/*.bottom-border{
     border-bottom: 2px solid #686C71; 
    border-bottom: 0.1rem solid rgba(1,26,33,0.1)
}*/

.content-item-edit-border {
    border: 2px solid #23549F;
    border-radius: 8px;
    background: rgba(35, 84, 159, 0.08);
    /* padding: 5px; */
}

.comment-indicator-border {
    background-color: #edc041;
    border-radius: 20px;
    height: 1.25rem;
    width: fit-content;
    padding: 2px 5px;
    color: #1c1c1c;
}

.notification-indicator-border--expand {
    position: absolute;
    top: 3px;
    left: 2px;
    /*  position: relative;
    top: 0px;*/
    /* right: 130px;*/
    margin-left: 0.75rem;
    background-color: #edc041;
    border-radius: 20px;
    height: 1rem;
    width: fit-content;
    padding: 2px 5px;
    color: #1c1c1c;
    line-height: 1rem;
}


.notification-indicator-border {
    position: relative;
    top: -14px;
    right: 14px;
    background-color: #edc041;
    border-radius: 20px;
    height: 1rem;
    width: fit-content;
    padding: 2px 5px;
    color: #1c1c1c;
    line-height: 1rem;
}

.notification-indicator-border--home {
    position: absolute;
    margin-left: 10px;
    top: 1rem;
    /* top: 1px;*/

    background-color: #edc041;
    border-radius: 20px;
    height: 1rem;
    width: fit-content;
    padding: 2px 5px;
    color: #1c1c1c;
}

.help-center-border {
    border: 2px solid #23549F;
    border-radius: 8px;
    /* padding: 5px; */
}


.usermanagement-ellipsis-button-border {
    border: 2px solid #E5E5E5;
    border-radius: 6px;
    padding: 8px, 12px, 8px, 12px;
    width: 32px;
    height: 22px;
   /* background: rgba(35, 84, 159, 0.08);*/
    /* padding: 5px; */
}

/* generic borders */

.border-bottom {
    border-bottom: 1px solid #E0E2E4;
}

.border-top {
    border-top: 1px solid #E0E2E4;
}

.border-right {
    border-right: 1px solid #E0E2E4;
}

.border-left {
    border-left: 1px solid #E0E2E4;
}

/*Thick Borders*/

.border-bottom__thick {
    border-bottom: 2px solid #E0E2E4;
}

.border-bottom__thick-blue {
    border-bottom: 2px solid #23549F;
}

.border-bottom__thick-gold {
    border-bottom: 2px solid #EDC041;
}

.border-top__thick {
    border-top: 2px solid #E0E2E4;
}

.border-right__thick {
    border-right: 2px solid #E0E2E4;
}

.border-left__thick {
    border-left: 2px solid #E0E2E4;
}

.border-left__thick-blue {
    border-left: 2px solid #23549F;
}

.border-left__thick-gold {
    border-left: 2px solid #EDC041;
}

.border-right__thick-gold {
    border-right: 2px solid #EDC041;
}

.border-top__thick-gold {
    border-top: 2px solid #EDC041;
}

/* table borders */

.border-bottom td {
    border-bottom: 1px solid #E0E2E4;
}

.border-left td:first-child {
    border-left: 1px solid #E0E2E4;
}

.border-right td:last-child {
    border-right: 1px solid #E0E2E4;
}
