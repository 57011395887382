
.page-container--login{
    height: 100vh;
    width: 100%;
    background: linear-gradient(154.16deg, #FFFFFF 7.96%, #FFFFFF 105.98%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .form-container{
    width: 29rem;
    height: 25.5rem;
    background-color: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
    display: flex;
    /* align-items: center; */
    justify-content: center;
   
  }
  
  .form-half-container{
    width: 28rem;
    height: 19.25rem;
    background-color: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
    display: flex;
    /* align-items: center; */
    justify-content: center;
   
  }
  
  .form-row{
    display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: space-between;
      margin-top: 1rem;
      margin-left: 1rem;
  }

  .form-row__login--controls{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    margin-left: 1rem;
  }

  .form-row-start{
    margin-right: auto;
  }

  .form-row-wide{
    margin: auto;
  }

  .form-row-end{
    margin-left: auto;
    display: inline-flex;
    margin-right: 1rem;
  }
  
  .img-logo{
    margin-left: auto;
    margin-right: auto;
    height: 8%;
    width: 8%;
  
  }
  
  .form-title{
    font-weight: normal;
    font-size: 24px;
    color: #1C1C1C;
  }


.form-title__login{
    text-align: center;
    margin-top: 40px;
    margin-bottom: 20px;
}

.form-text-box__login{
    /* width: 100%; */
    width: 415px;
    height: 40px;
    border: 1px solid #ECEEEF;
    border-radius: 2px;
    background-color: #F6F7F8;
    color: #9599A1;
    

}

.form-text-box__login:last-of-type{
    /* margin-bottom: 20px; */
}



.link-forgot-password{
    color: #23549F !important;
}

.form-btn__signup{
    width: 200px;
    height: 40px;
    color: #23549F;
    background-color: #FFFFFF;
    border: 1px solid #E0E2E4;
    border-radius: 2px;
    justify-content: center;
}

.form-btn__login{
    width: 200px;
    height: 40px;
    color: #FFFFFF;
    background-color: #23549F;
    border: 1px solid #E0E2E4;
    border-radius: 2px;
    justify-content: center;

}

.form-span__alternative-signin-title{
    font-family: sans-serif;
    margin: 10px auto;
    text-align: center;
    /* color: black; */
    font-size: 14px;
    max-width: 600px;
    position: relative;
    z-index: 50;
    
    color: #9599A1;
    font-weight: 400;
    margin-top: 40px;
}



/* .form-span__alternative-signin-title::before {
        content: "";
        display: block;
        width: 200px;
        height: 2.5px;
        background: #CD5C5C;
        left: 85px;
        top: 50%;
        position: absolute;
}

.form-span__alternative-signin-title::after{
        content: "";
        display: block;
        width: 200px;
        height: 2.5px;
        background: #CD5C5C;
        right: 85px;
        top: 50%;
        position: absolute;
} */


.btn__alternative-signin{
    width: 122.67px;
    height: 56px;
    background-color: #F6F7F8;
    border-radius: 8px;
    margin-right: 1.25rem;
}

.icon__alternative-signin{
    /* align-self: center;
    align-content: center; */
    margin-top: 1rem;
    display: block;
    margin-left: auto;
     margin-right: auto;
    /* width: 17.22px; */
    height: 20.22px;
    width: 100%;
    /* height: 100%; */
}

.icon-color--facebook{
    color:#23549F;
    
}