.wrapper{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 200px;
}

.one{
    grid-column: 1/3;
    grid-row: 1;
}

.two{
    grid-column: 2;
    grid-row: 1;
}

.three{
    grid-column: 1;
    grid-row: 2;
}

.four{
    grid-column: 2;
    grid-row: 2/5;
}