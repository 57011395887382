.breaker-dot {
    height: 6px;
    width: 6px;
    color: #D4D4D4;
    
}

.active-navLink {
    color: #1E40AF;
    background-color: #EFF6FF !important;
}

.active-navLink:hover {
    background-color: #EFF6FF !important;
}

.active-navLink:focus {
    background-color: #EFF6FF !important;
}
