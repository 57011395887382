.fixed {
    position: fixed;
}

.sticky {
    position: sticky;
}

.static {
    position: static;
}

.absolute {
    position: absolute;
}

.flex {
    display: flex;
}

.flex-flex-end {
    display: flex;
    justify-content: flex-end;
}

.flex-flex-start {
    display: flex;
    justify-content: flex-start;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
    margin-top: 1rem;
    margin-left: 1rem;
}

.subrow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
    margin-top: 0.5rem;
    margin-left: 1rem;
}

.center {
    margin-right: auto;
    margin-left: auto;
    display: inline-flex;
}

.margin-bottom-auto {
    margin-top: auto;
}

.margin-top-auto {
    margin-bottom: auto;
}

.percentspacer-margin-top {
    margin-top: 0%;
}

.fiftypercentspacer-margin-bottom {
    margin-bottom: 50%;
}

.row-left {
    margin-right: auto;
}

.row-right {
    margin-left: auto;
    display: inline-flex;
}

.row-top {
    margin-bottom: auto;
    display: inline-flex;
}

.row-bottom {
    margin-top: auto;
    display: inline-flex;
}


.row-no-left-margin {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
    margin-top: 1rem;
}

.subrow-no-left-margin--smaller {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
    margin-top: 0.25rem;
}

.subrow-no-left-margin {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
    margin-top: 0.5rem;
}

.subrow-no-margin {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
}

.row-no-margin {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
}

.row-no-margin-no-wrap {
    display: flex;
    flex-direction: row;
    align-content: space-between;
}

.row-no-left-margin {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
    margin-top: 1rem;
}

.row-no-top-margin {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
    margin-left: 1rem;
}

.spacer-margin-left {
    margin-left: 1rem;
}

.spacer-margin-right {
    margin-right: 1rem;
}

.spacer-margin-bottom {
    margin-bottom: 1rem;
}


.spacer-margin-top {
    margin-top: 1rem;
}

.doublespacer-margin-left {
    margin-left: 2rem;
}

.doublespacer-margin-right {
    margin-right: 2rem;
}

.doublespacer-margin-bottom {
    margin-bottom: 2rem;
}


.doublespacer-margin-top {
    margin-top: 2rem;
}

.doublehalfspacer-margin-left {
    margin-left: 2.5rem;
}

.doublehalfspacer-margin-right {
    margin-right: 2.5rem;
}

.doubleshalfpacer-margin-bottom {
    margin-bottom: 2.5rem;
}


.doublehalfspacer-margin-top {
    margin-top: 2.5rem;
}

.triplespacer-margin-left {
    margin-left: 3rem;
}

.triplespacer-margin-right {
    margin-right: 3rem;
}

.triplespacer-margin-bottom {
    margin-bottom: 3rem;
}


.triplespacer-margin-top {
    margin-top: 3rem;
}

.triplehalfspacer-margin-left {
    margin-left: 3.5rem;
}

.triplehalfspacer-margin-right {
    margin-right: 3.5rem;
}

.triplehalfspacer-margin-bottom {
    margin-bottom: 3.5rem;
}


.triplehalfspacer-margin-top {
    margin-top: 3.5rem;
}

.quadspacer-margin-left {
    margin-left: 4rem;
}

.quadspacer-margin-right {
    margin-right: 4rem;
}

.quadspacer-margin-bottom {
    margin-bottom: 4rem;
}


.quadspacer-margin-top {
    margin-top: 4rem;
}


.quadhalfspacer-margin-left {
    margin-left: 4.5rem;
}

.quadhalfspacer-margin-right {
    margin-right: 4.5rem;
}

.quadhalfspacer-margin-bottom {
    margin-bottom: 4.5rem;
}


.quadhalfspacer-margin-top {
    margin-top: 4.5rem;
}




.threequarterspacer-margin-top {
    margin-top: 0.75rem;
}

.threequarterspacer-margin-left {
    margin-left: 0.75rem;
}

.threequarterspacer-margin-right {
    margin-right: 0.75rem;
}

.threequarterspacer-margin-bottom {
    margin-bottom: 0.75rem;
}

.halfspacer-margin-left {
    margin-left: 0.5rem;
}

.halfspacer-margin-right {
    margin-right: 0.5rem;
}

.halfspacer-margin-bottom {
    margin-bottom: 0.5rem;
}

.halfspacer-margin-top {
    margin-top: 0.5rem;
}



.quarterspacer-margin-top {
    margin-top: 0.25rem;
}

.quarterspacer-margin-left {
    margin-left: 0.25rem;
}

.quarterspacer-margin-right {
    margin-right: 0.25rem;
}

.quarterspacer-margin-bottom {
    margin-bottom: 0.25rem;
}

.halfquarterspacer-margin-top {
    margin-top: 0.125rem;
}

.halfquarterspacer-margin-left {
    margin-left: 0.125rem;
}

.halfquarterspacer-margin-right {
    margin-right: 0.125rem;
}

.halfquarterspacer-margin-bottom {
    margin-bottom: 0.125rem;
}

/* special cases */
.fivespacer-margin-left {
    margin-left: 5rem;
}

.fivespacer-margin-right {
    margin-right: 5rem;
}

.fivespacer-margin-bottom {
    margin-bottom: 5rem;
}


.fivespacer-margin-top {
    margin-top: 5rem;
}

.sixspacer-margin-top {
    margin-top: 6rem;
}

.sixspacer-margin-left {
    margin-left: 6rem;
}

.sixspacer-margin-right {
    margin-right: 6rem;
}



.sixspacer-margin-bottom {
    margin-bottom: 6rem;
}

.sevenspacer-margin-top {
    margin-top: 7rem;
}

.sevenspacer-margin-left {
    margin-left: 7rem;
}

.sevenspacer-margin-right {
    margin-right: 7rem;
}

.sevenspacer-margin-bottom {
    margin-bottom: 7rem;
}

.sevenhalfspacer-margin-top {
    margin-top: 7.5rem;
}

.sevenhalfspacer-margin-left {
    margin-left: 7.5rem;
}

.sevenhalfspacer-margin-right {
    margin-right: 7.5rem;
}

.sevenhalfspacer-margin-bottom {
    margin-bottom: 7.5rem;
}

.eightspacer-margin-bottom {
    margin-bottom: 8rem;
}

.eightspacer-margin-top {
    margin-top: 8rem;
}

.eightspacer-margin-left {
    margin-left: 8rem;
}

.eightspacer-margin-right {
    margin-right: 8rem;
}



.tenspacer-margin-bottom {
    margin-bottom: 10rem;
}

.tenspacer-margin-top {
    margin-top: 10rem;
}

.tenspacer-margin-left {
    margin-left: 10rem;
}

.tenspacer-margin-right {
    margin-right: 10rem;
}


.elevenspacer-margin-bottom {
    margin-bottom: 11rem;
}

.elevenspacer-margin-top {
    margin-top: 11rem;
}

.elevenspacer-margin-left {
    margin-left: 11rem;
}

.elevenspacer-margin-right {
    margin-right: 11rem;
}

.elevenhalfspacer-margin-bottom {
    margin-bottom: 11.5rem;
}

.elevenhalfspacer-margin-top {
    margin-top: 11.5rem;
}

.elevenhalfspacer-margin-left {
    margin-left: 11.5rem;
}

.elevenhalfspacer-margin-right {
    margin-right: 11.5rem;
}



.twelvespacer-margin-bottom {
    margin-bottom: 12rem;
}

.twelvespacer-margin-top {
    margin-top: 12rem;
}

.twelvespacer-margin-left {
    margin-left: 12rem;
}

.twelvespacer-margin-right {
    margin-right: 12rem;
}

.thirteenspacer-margin-bottom {
    margin-bottom: 13rem;
}

.thirteenspacer-margin-top {
    margin-top: 13rem;
}

.thirteenspacer-margin-left {
    margin-left: 13rem;
}

.thirteenspacer-margin-right {
    margin-right: 13rem;
}

.fourteenspacer-margin-bottom {
    margin-bottom: 14rem;
}

.fourteenspacer-margin-top {
    margin-top: 14rem;
}

.fourteenspacer-margin-left {
    margin-left: 14rem;
}

.fourteenspacer-margin-right {
    margin-right: 14rem;
}

.quarterplusone-margin-left {
    margin-left: 1.25rem;
}



.halfplusonespacer-margin-left {
    margin-left: 1.5rem;
}

.halfplusonespacer-margin-right {
    margin-right: 1.5rem;
}

.threequarterplusonespacer-margin-left {
    margin-left: 1.75rem;
}

.threequarterplusonespacer-margin-right {
    margin-right: 1.75rem;
}

.thirtyfourspacer-margin-left {
    margin-left: 34rem;
}

.thirtysixspacer-margin-left {
    margin-left: 36rem;
}


/* table */

.table-padding-right {
    padding-right: 6rem;
}

/* flex*/

.flex-row {
    display: flex;
    flex-direction: row;
}

/* row-grids */

.row-grid-claimlink {
    display: grid;
    /* grid-auto-flow: row; */
    grid-template-columns: repeat(10, 1fr);
}


.ninetysixpxspacer-margin-left {
    margin-left: 96px;
}
