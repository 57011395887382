.modal-create-view__category-members {
    /* margin-top: 5%;
    margin-left: 34%; */
    /* background-color: #EDC041; */
    background-color: hsl(0, 0%, 100%);
    /* width: 725px; */
    /* width: fit-content; */
    /* height: 700px; */
    height: fit-content;
}



.modal-create-view__category-claims {
    /* margin-top: 5%;
    margin-left: 34%; */
    /* background-color: #EDC041; */
    background-color: hsl(0, 0%, 100%);
    /* width: 725px; */
    width: 100%;
    /* height: 700px; */
    height: fit-content;
}



.modal-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
    margin-top: 1rem;
    margin-left: 1rem;
}

.modal-row--left {
    margin-right: auto;
}

.modal-row--right {
    margin-left: auto;
    display: inline-flex;
    margin-right: 1rem;
}

.modal-close-button {
    font-size: 30px;
    color: black;
    transform: scale(1.25);
}

.modal-row--start {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
    margin-top: 1rem;
    margin-left: 1rem;
    padding-top: 1rem;
}

.modal-row--end {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
    margin-top: 1rem;
    margin-left: 1rem;
    padding-bottom: 1rem;
}

.modal-row--sub-script {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
    margin-top: 0.1rem;
    margin-left: 1rem;
}

.modal-block__view-details {
    width: 100%;
    /* height: 50%; */
    background-color: #F6F7F8;
    /* background-color:#ECEEEF; */
    /* #F6F7F8; */
}



.modal-detail__badge--blue {
    background-color: #23549F;
    border: none;
    color: #FFFFFF;
    /* padding: 0.188rem 0.563rem; */
    padding: 0.1rem 0.45rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 0.75rem;
    font-size: 0.875rem;
    font-weight: 550;
    margin-right: 1rem;
}

.modal-detail__badge--grey {
    background-color: #E0E2E4;
    border: none;
    color: #000000;
    /* padding: 0.188rem 0.563rem; */
    padding: 0.1rem 0.45rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 0.75rem;
    font-size: 0.875rem;
    font-weight: 550;
    margin-right: 1rem;
}

.modal-cancel-btn {
    width: 85px;
    height: 40px;
    /* padding-left: 5px; */
    border-radius: 2px;
    background-color: #FFFFFF;
    color: #23549F;
    border: 1px solid #E0E2E4;
}

    .modal-cancel-btn:disabled {
        /* background:#ECEEEF; */
        color: #54575C;
        /* color:#E0E2E4; */
        background: #ECEEEF;
    }

    .modal-cancel-btn:focus {
        border: 2px solid #000000;
    }

.modal-create-view-btn:focus {
    border: 2px solid #000000;
}

.modal-mark-sample-btn:focus {
    border: 2px solid #000000;
}

.modal-mark-candidate-btn {
    width: 160px;
    height: 40px;
    /* padding-left: 5px; */
    border-radius: 2px;
    background-color: #FFFFFF;
    color: #23549F;
    border: 1px solid #E0E2E4;
    margin-left: 1rem;
}

.modal-create-view-btn {
    width: 125px;
    height: 40px;
    border-radius: 2px;
    background-color: #23549F;
    color: #FFFFFF;
    margin-right: -1rem;
    margin-left: 1rem;
}



.modal-mark-sample-btn {
    width: 145px;
    height: 40px;
    border-radius: 2px;
    background-color: #23549F;
    color: #FFFFFF;
    margin-right: -1rem;
    margin-left: 1rem;
}

.modal-input__text--view-name {
    font-family: Inter;
    font-size: 14px;
    width: 300px;
    height: 40px;
    background-color: #F6F7F8;
    border: 1px solid #ECEEEF;
}

.modal-input__text--view-name--longer {
    font-family: Inter;
    font-size: 14px;
    width: 400px;
    height: 40px;
    background-color: #F6F7F8;
    border: 1px solid #ECEEEF;
}

.modal-input__textarea {
    font-family: Inter;
    font-size: 14px;
    width: 616px;
    height: 70px;
    background-color: #F6F7F8;
    border: 1px solid #ECEEEF;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

    .modal-input__textarea::-webkit-scrollbar {
        /* Hide scrollbar for Chrome, Safari and Opera */
        display: none;
    }

.modal-input__textarea--no-height-bound {
    font-family: Inter;
    font-size: 14px;
    width: 616px;
    height: fit-content;
    background-color: #F6F7F8;
    border: 1px solid #ECEEEF;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

    .modal-input__textarea--no-height-bound::-webkit-scrollbar {
        /* Hide scrollbar for Chrome, Safari and Opera */
        display: none;
    }


.modal-input__textarea--smaller {
    font-family: Inter;
    font-size: 14px;
    width: 556px;
    height: 70px;
    background-color: #F6F7F8;
    border: 1px solid #ECEEEF;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

    .modal-input__textarea--smaller::-webkit-scrollbar {
        /* Hide scrollbar for Chrome, Safari and Opera */
        display: none;
    }

.modal-input__text--report-builder-field {
    font-family: Inter;
    font-size: 14px;
    width: 200px;
    height: 40px;
    background-color: #F6F7F8;
    border: 1px solid #ECEEEF;
}

.modal-input__text--report-builder-field--strata-range {
    font-family: Inter;
    font-size: 14px;
    width: 160px;
    height: 40px;
    background-color: #F6F7F8;
    border: 1px solid #ECEEEF;
}

.modal-input__text--report-builder-field--fig-field {
    font-family: Inter;
    font-size: 14px;
    width: 90px;
    height: 40px;
    background-color: #F6F7F8;
    border: 1px solid #ECEEEF;
}

.modal-input__text--report-builder-field--medium {
    font-family: Inter;
    font-size: 14px;
    width: 280px;
    height: 40px;
    background-color: #F6F7F8;
    border: 1px solid #ECEEEF;
}

.modal-input__text--report-builder-field__small {
    font-family: Inter;
    font-size: 14px;
    width: 62.5px;
    height: 40px;
    background-color: #F6F7F8;
    border: 1px solid #ECEEEF;
    word-spacing: 50px;
}

.modal-title {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #1C1C1C;
}

.modal-description {
    color: black;
}

.modal-label--small__stacked {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #1C1C1C;
    word-spacing: 50px;
    width: 50px;
}

.modal-label--small {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #1C1C1C;
}

.modal-label--large {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    margin-left: 0.5rem;
}

.modal-text--large {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    /* margin-left: 0.5rem; */
}

.modal-left-box {
    height: 100px;
    width: 50%;
    display: inline-block;
    /* background-color: #23549F; */
    margin-bottom: 2rem;
}

.modal-right-box {
    height: 100px;
    width: 50%;
    display: inline-block;
    /* background-color: #23549F; */
    margin-bottom: 2rem;
    /* margin-left: 0.5rem; */
}

.modal-text-box {
    height: 83px;
    width: 95%;
}

.modal-label--note-detail {
    font-weight: normal;
    font-size: 12px;
    color: #686C71;
    display: inline-block;
    margin-right: 0.5rem;
}



.modal-text-detail--small {
    font-weight: normal;
    font-size: 14px;
    color: #686C71;
}

.modal-label--sample-note {
    font-weight: normal;
    font-size: 12px;
    color: #1C1C1C;
}
