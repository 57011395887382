/*Imported fonts*/

@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700;800&display=swap');

/*@font-face {
    font-family: "Mulish";
    src: url('../src/fonts/Mulish/static/Mulish-Regular.ttf') format("truetype");
}

@font-face {
    font-family: "Mulish";
   
    src: url('../src/fonts/Mulish/static/Mulish-Medium.ttf') format("truetype");
}


@font-face {
    font-family: "Mulish";
   
    src: url('../src/fonts/Mulish/static/Mulish-SemiBold.ttf') format("truetype");
}

@font-face {
    font-family: "Mulish";
   
    src: url('../src/fonts/Mulish/static/Mulish-Bold.ttf') format("truetype");
}

@font-face {
    font-family: "Mulish";
   
    src: url('../src/fonts/Mulish/static/Mulish-ExtraBold.ttf') format("truetype");
}*/




body {
  margin: 0;
    font-family: -apple-system, BlinkMacSystemFont,'Mulish', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
